import styled from 'styled-components';
import {
  CricketScoreBoardBackground,
  CricketOutRedBall,
  MetalBeamBackground,
  ScoreBoardBraces,
} from 'assets/images';

export const Wrapper = styled.div`
  position: relative;
	display: flex;
  width: 395px;
  height: 196px;
  z-index: 3;
  margin-bottom: 8px;

  .fullcard {
    margin-top: 2px;
  }

  .fullcard-pt {
    margin-top: 2px;
  }

  .winnings {
    margin-top: 7px;
  }
  .winnings-pt, .winnings-ptBR, .winnings-es {
    margin-top: 7px;
  }



  .hit-streak {
    margin-top: 7px;
    /* @media only screen and (max-height: 640px), screen and (max-width: 430px) {
      margin-top: 10px;
    } */
  }

  .hit-streak-pt {
    font-size: 1em !important;
    margin-top: 8px;
    @media only screen and (max-height: 640px), screen and (max-width: 430px) {
      margin-top: 8px;
    }
  }
  .hit-streak-label {
    font-size: 1em;
  }
  .hit-streak-label-pt {
    margin-top: 2px;
    font-size: 0.8em;
  }
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-image: url(${CricketScoreBoardBackground});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* padding-top: 68px; */
  padding-top: 70px;

  padding-left: 17px;
  z-index: 1;
  @media only screen and (max-height: 640px), screen and (max-width: 430px) {
    padding-top: 68px;
  }
`;

export const Braces = styled.div`
  background-image: url(${ScoreBoardBraces});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 450px;
  height: 120px;
  position: absolute;
  z-index: 0;
  bottom: -10px;
  margin-left: -25px;
`;

export const MetalBeam = styled.div`
  background-image: url(${MetalBeamBackground});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 381px;
  height: 22px;
  position: absolute;
  z-index: 2;
  bottom: -14px;
  margin-left: 8px;
`;

export const YellowLabel = styled.div`
  text-shadow: 0.1px 2px 5px rgba(0, 0, 0, 0.08);
  font-family: 'Varsity Team Bold';
  /* font-size: 1.2em; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #face00;
  text-transform: uppercase;
  position: relative;
  z-index: -1;

  &.outs {
    width: 50px;
    margin-left: 40px;
    @media only screen and (max-height: 640px), screen and (max-width: 430px) {
      margin-left: 29px;
    }
    display: flex;
    flex: 1;
    height: 25px;
    line-height: 25px;
    position: relative;
    z-index: -1;

    font-size: 18px;

    > div:first-child {
      width: 56px;
    }
    .first-pt {
      margin-left: -32px;
    }
  }
  &.outs-pt {
    margin-left: 36px !important;
  }

  &.hit-streak-label {
    width: unset;
  }
`;

export const RedValue = styled.div`
  width: 50%;
  font-family: "5by7";
  font-size: 17.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff001e;
  padding-left: 10px;
`;

export const ScoreBoardContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 1fr;
`

export const YellowLabelContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  display: grid;
  grid-template-columns: 50% 1fr;
  align-items: baseline;
  /* div {
    display: grid;
    grid-template-columns: 50% 1fr;
    align-items: baseline;
  } */
`;

export const OutsLevelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
  padding-left: 5px;
  height: 25px;
  @media only screen and (max-height: 640px), screen and (max-width: 430px) {
    padding-top: 3px;
    padding-left: 1px;
  }
`;

export const GreyLabel = styled.div`
  text-shadow: 0.1px 2px 5px rgba(0, 0, 0, 0.08);
  font-family: 5by7;
  font-size: 18.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4a4848;
  width: 170px;
  overflow: hidden;
  text-transform: uppercase;
  position: relative;
  height: 100%;
  @media only screen and (max-height: 640px), screen and (max-width: 430px) {
    width: 181px;
  }
`;

export const GreyLabelContents = styled.div`
  white-space: nowrap;
  position: absolute;
  width: 200%;
  height: 100%;
  left: -100%;
  @media only screen and (max-height: 640px), screen and (max-width: 428px) {
    /* left: -92%; */
  }
  text-align: center;
  display: flex;
  justify-content: center;
  /* align-items: center; */

  div {
    font-family: 'Varsity Team Bold';
    width: 50%;
  }
  .first-pt  {
    width: 300px;
    margin-left: -100px;
  }

  ${prop => prop.animate ? `
    color: #face00;
    animation-delay: 0;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: linear;
    animation-name: marquee, hide;
  ` : null}

  @keyframes hide {
    0%, 20% {
      opacity: 1;
    }

    100% {
      opacity: 25;
    }
  }

  @keyframes marquee {
    0%, 20% {
      left: -100%;
    }
    100% {
      left: 0%
    }
  }
`

export const HitStreakContainer = styled.div`
  display: flex;
  position: relative;
  top: -1px;
  width: 263px;
  justify-content: flex-start;
  align-items: center;
  left: -74px;
  @media only screen and (max-height: 640px), screen and (max-width: 430px) {
    top: 1px;
    /* left: -97px; */
  }
`;

export const Hit = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #4eff00;
  margin-right: 6px;

  &:nth-child(4),
  &:nth-child(6),
  &:nth-child(9),
  &:nth-child(10),
  &:nth-child(12),
  &:nth-child(15) {
    margin-left: 1px;
  }
`;

export const OutsContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: -1px;
  align-items: center;
`;

export const OutBall = styled.div`

  width: 22px;
  height: 22px;
  background-image: url(${CricketOutRedBall});
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @media screen and (max-width: 431px) {
    margin-left: 4px;
    margin-top: 2px;
  }

  &:nth-child(2) {
    margin-left: 9px;
  }

  &:nth-child(3) {
    margin-left: 10px;
  }

  ${prop => prop.shouldRotate ? `
    animation: rotate 0.6s infinite linear;
  `: null};

  @keyframes rotate {
    100% {
      transform: rotateZ(360deg);
    }
  }
`;

export const PrizeMoneyContainer = styled.div`
  font-family: 'Varsity Team Bold';
  font-size: 18.5px;
  font-weight: bold;
  color: #face00;
  text-align: center;
  position: fixed;
  right: 0;
  left: 0;
  margin-left: -170px;
  animation: moveSize 1s linear 0.5s forwards, blink 0.5s infinite linear;
  z-index: 4;

  @keyframes moveSize {
    100% {
      margin-left: 0;
      margin-top: 230px;
      font-size: 80pt;
    }
  }

  @keyframes blink {
    0% {
      color: #face00;
    }
    100% {
      color: #ffffff;
    }
  }
`

export const Blanket = styled.div`
  position: fixed;
  z-index: 3;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: -5000%;
  bottom: 0;
  right: -5000%;
`